
import { computed, onMounted, ref, watch } from "vue";

export default {
  name: "QuotationResponse",
  props: {
    quotation: {
      type: Object,
      default: null,
    },
  },
};
